<template>
    <select class="select select--black" name="filter" @change="changeHandler" v-model="value" :aria-label="ariaLabel">
        <option :value="entryUrl">{{ filterName }}</option>
        <option v-for="(option, key) in options" :value="option.value" :key="key">
            {{ option.label }}
        </option>
    </select>
</template>

<script>
    export default {
        name: "SelectFilter",

        props: {
            entryUrl: {
                type: String,
                required: true,
            },
            currentUrl: {
                type: String,
                required: true,
            },
            filterName: {
                type: String,
                required: true,
            },
            ariaLabel: {
                type: String,
                required: true,
            },
            options: {
                type: Array,
                required: true,
            },
        },

        data() {
            return {
                value: null,
            }
        },

        mounted() {
            this.value = this.currentUrl;
        },

        methods: {
            changeHandler() {
                window.location.href = this.value;
            },
        },
    }
</script>
