<template>
    <header class="header-nav" :class="hasDarkBackground ? 'text-white': 'text-black'" :style="`background-color: ${backgroundColor}`">
        <header-banner
            :banner="banner"
            :aria-label-navigation="ariaLabelNavigationBanner"
        />

        <div :class="{'border-b border-black': hasDarkBorder}">
            <nav class="grid-container container flex justify-between items-center py-2 lg:py-3" :aria-label="ariaLabelNavigationHeader">
                <div class="flex">
                    <button class="lg:hidden mr-6 focus:outline-none" @click="isMenuOpened = true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <g fill="none" fill-rule="evenodd">
                                <g fill="currentColor" fill-rule="nonzero">
                                    <g>
                                        <path d="M16 15v1H0v-1h16zm0-7.5v1H0v-1h16zM16 0v1H0V0h16z" transform="translate(-16 -20) translate(16 20)"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>

                    <a :href="currentSiteUrl" :title="homeLinkTitle">
                        <img class="header-nav__logo" :src="currentLogo.url" :alt="currentLogo.alt">
                    </a>
                </div>


                <template v-for="(item, key) in menuItems">
                    <a
                        v-if="item"
                        :key="`menu-item-${key}`"
                        :href="item.url"
                        :target="item.target"
                        class="hidden lg:flex body no-underline"
                    >
                        {{ item.label }}
                    </a>
                </template>

                <div class="flex">
                    <div class="flex mr-6">
                        <template v-for="(item, key) in banner.languagePicker">
                            <a
                                class="lg:hidden font-gt-america-regular no-underline uppercase"
                                v-if="!item.isActive"
                                :href="item.url"
                                :key="`banner-language-${key}`"
                            >{{ item.locale }}</a>
                        </template>
                    </div>

                    <button @click="isSearchOpened = true">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <g>
                                    <g fill="currentColor" fill-rule="nonzero">
                                        <path d="M9 .5c4.694 0 8.5 3.806 8.5 8.5 0 2.167-.811 4.145-2.146 5.646L21.707 21l-.707.707-6.354-6.353C13.145 16.689 11.167 17.5 9 17.5 4.306 17.5.5 13.694.5 9S4.306.5 9 .5zm0 1C4.858 1.5 1.5 4.858 1.5 9c0 4.142 3.358 7.5 7.5 7.5 2.024 0 3.86-.801 5.209-2.104l.19-.19C15.7 12.855 16.5 11.021 16.5 9c0-4.142-3.358-7.5-7.5-7.5z" transform="translate(1 1)"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </button>
                </div>
            </nav>
        </div>

        <fade>
            <header-search
                v-if="isSearchOpened"
                :logo="logo.light"
                :search-form-action="searchFormAction"
                :search-input-placeholder="searchInputPlaceholder"
                @close="isSearchOpened = false"
            ></header-search>
        </fade>

        <fade>
            <header-menu-mobile
                v-if="isMenuOpened"
                :aria-label-navigation-header="ariaLabelNavigationHeader"
                :aria-label-navigation-banner="ariaLabelNavigationBanner"
                :background-color="backgroundColor"
                :has-dark-background="hasDarkBackground"
                :logo="currentLogo"
                :current-site-url="currentSiteUrl"
                :home-link-title="homeLinkTitle"
                :menu-items="menuItems"
                :banner="banner"
                @close="isMenuOpened = false"
            ></header-menu-mobile>
        </fade>
    </header>
</template>

<script>
    import HeaderBanner from "./HeaderBanner";
    import HeaderSearch from "./HeaderSearch";
    import HeaderMenuMobile from "./HeaderMenuMobile";
    import Fade from "../transitions/Fade";

    export default {
        name: "HeaderNav",

        props: {
            menuItems: {
                type: Array,
                required: true,
            },
            backgroundColor: {
                type: String,
                required: true,
            },
            hasDarkBackground: {
                type: Boolean,
                required: true,
            },
            hasDarkBorder: {
                type: Boolean,
                required: true,
            },
            logo: {
                type: Object,
                required: true,
            },
            banner: {
                type: Object,
                required: true,
            },
            searchFormAction: {
                type: String,
                required: true,
            },
            searchInputPlaceholder: {
                type: String,
                required: true,
            },
            currentSiteUrl: {
                type: String,
                required: true,
            },
            homeLinkTitle: {
                type: String,
                required: true,
            },
            ariaLabelNavigationBanner: {
                type: String,
                required: true,
            },
            ariaLabelNavigationHeader: {
                type: String,
                required: true,
            },
        },

        components: {
            HeaderBanner,
            HeaderSearch,
            HeaderMenuMobile,
            Fade,
        },

        data() {
            return {
                isSearchOpened: false,
                isMenuOpened: false,
            }
        },

        computed: {
            currentLogo() {
                return this.hasDarkBackground ? this.logo.light : this.logo.dark;
            },
        },
    }
</script>

<style scoped lang="scss">
    .header-nav {
        &__logo {
            width: 2.375rem;
            height: 2.5rem;

            @screen lg {
                @apply w-auto h-auto;
            }
        }
    }
</style>
