<template>
    <div :id="identifier"></div>
</template>

<script>
export default {
    name: "Anchor",

    data() {
        return {
            identifier: null,
        }
    },

    props: {
        anchorId: {
            type: String,
            required: true,
        }
    },

    mounted() {
        if (window.location.hash === '#' + encodeURI(this.anchorId)) {
            setTimeout(() => {
                this.identifier = this.anchorId;

                this.$el.scrollIntoView({behavior: 'smooth'});
            }, 1000);
        } else {
            this.identifier = this.anchorId;
        }
    },
}
</script>
