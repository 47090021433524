<template>
    <nav class="header-banner hidden lg:flex justify-end items-center bg-black text-white px-10 py-2"
         :aria-label="ariaLabelNavigation"
    >
        <a
            v-for="(item, key) in banner.menuItems"
            :href="item.url"
            :target="item.target"
            :key="`banner-menu-item-${key}`"
            class="header-banner__menu-item mr-6 font-gt-america-regular no-underline uppercase"
        >
            {{ item.label }}
        </a>

        <a
            v-for="(item, key) in banner.socialNetworks"
            :href="item.url"
            target="_blank"
            :title="item.label"
            :key="`banner-social-networks-${key}`"
            class="mr-4"
        >
            <img class="w-4 h-4" :src="item.logo.light.url" :alt="item.logo.light.alt">
        </a>

        <template v-for="(item, key) in banner.languagePicker">
            <a
                class="header-banner__menu-item font-gt-america-regular no-underline uppercase"
                v-if="!item.isActive"
                :href="item.url"
                :key="`banner-language-${key}`"
            >{{ item.locale }}</a>
        </template>
    </nav>
</template>

<script>
    export default {
        name: "HeaderBanner",

        props: {
            banner: {
                type: Object,
                required: true,
            },
            ariaLabelNavigation: {
                type: String,
                required: true,
            },
        },
    }
</script>

<style scoped lang="scss">
    .header-banner {
        &__menu-item {
            font-size: 0.6875rem;
            letter-spacing: 0.114em;
            opacity: 0.8;
        }
    }
</style>
