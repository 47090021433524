<template>
    <div class="header-search fixed inset-0 z-10 min-h-screen w-screen overflow-auto">
        <div class="grid-container container pt-2 lg:pt-10">
            <div class="grid-row">
                <div class="grid-col w-full flex justify-between items-center">
                    <img class="header-search__logo" :src="logo.url" :alt="logo.alt">

                    <button @click="$emit('close')">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g fill="none" fill-rule="evenodd">
                                <g>
                                    <path fill="#FFF" fill-rule="nonzero" d="M20.678 3l.707.707-8.486 8.485 8.486 8.486-.707.707-8.486-8.486-8.485 8.486L3 20.678l8.485-8.486L3 3.707 3.707 3l8.485 8.485L20.678 3z"/>
                                </g>
                            </g>
                        </svg>
                    </button>
                </div>

                <div class="grid-col w-full mt-40 pb-10">
                    <form :action="searchFormAction">
                        <div class="flex items-center border-b border-white pb-4 lg:pb-5">
                            <input
                                ref="input"
                                type="search"
                                name="q"
                                :placeholder="searchInputPlaceholder"
                                class="header-search__input flex-1 w-full bg-transparent text-white h2 focus:outline-none"
                            >

                            <button class="flex-shrink-0 flex-grow-0" type="submit">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <g fill="none" fill-rule="evenodd">
                                        <g>
                                            <g fill="#fff" fill-rule="nonzero">
                                                <path d="M9 .5c4.694 0 8.5 3.806 8.5 8.5 0 2.167-.811 4.145-2.146 5.646L21.707 21l-.707.707-6.354-6.353C13.145 16.689 11.167 17.5 9 17.5 4.306 17.5.5 13.694.5 9S4.306.5 9 .5zm0 1C4.858 1.5 1.5 4.858 1.5 9c0 4.142 3.358 7.5 7.5 7.5 2.024 0 3.86-.801 5.209-2.104l.19-.19C15.7 12.855 16.5 11.021 16.5 9c0-4.142-3.358-7.5-7.5-7.5z" transform="translate(1 1)"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HeaderSearch",

        props: {
            logo: {
                type: Object,
                required: true,
            },
            searchFormAction: {
                type: String,
                required: true,
            },
            searchInputPlaceholder: {
                type: String,
                required: true,
            },
        },

        mounted() {
            this.$refs.input.focus();
        },
    }
</script>

<style scoped lang="scss">
    .header-search {
        background-color: rgba(15, 15, 15, 0.98);

        &__logo {
            width: 2.375rem;
            height: 2.5rem;

            @screen lg {
                @apply w-auto h-auto;
            }
        }

        &__input {
            &::placeholder {
                @apply text-white;
            }

            &::-webkit-search-decoration,
            &::-webkit-search-cancel-button,
            &::-webkit-search-results-button,
            &::-webkit-search-results-decoration {
                @apply hidden;
            }
        }
    }
</style>
