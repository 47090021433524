<template>
    <fade>
        <a v-if="isVisible" href="#" class="scroll-to-top fixed bg-black rounded-full text-white p-4">
            Scroll to top
        </a>
    </fade>
</template>

<script>
import Fade from "@/app/transitions/Fade";

export default {
    name: "ScrollToTop",

    components: {
        Fade
    },

    data() {
        return {
            isVisible: false,
        }
    },

    mounted() {
        window.addEventListener('scroll', this.scrollHandler);
    },

    destroyed() {
        window.removeEventListener('scroll', this.scrollHandler);
    },

    methods: {
        scrollHandler() {
            this.isVisible = window.scrollY >= window.innerHeight;
        },
    }
}
</script>

<style scoped lang="scss">
    .scroll-to-top {
        bottom: 1rem;
        right: 1rem;
        font-size: 0;

        &::after {
            content: '';
            @apply flex w-6 h-6 bg-cover bg-center transform -rotate-90;
            background-image: url('~@/static/images/icons/arrow-white.svg');
        }

        @screen lg {
            bottom: 2rem;
            right: 2rem;
        }
    }
</style>
