<template>
    <div class="navigation-wrapper relative px-4">
        <div ref="slider" class="keen-slider">
            <slot name="slides"></slot>
        </div>
        <button
            v-if="current > 0"
            :class="`arrow-btn--${arrowsColor}`"
            class="absolute arrow-btn arrow-btn--left"
            @click="slider.prev()"
            aria-label="Slide précédente"
        ></button>
        <button
            v-if="current < nbSlides -1"
            :class="`arrow-btn--${arrowsColor}`"
            class="absolute arrow-btn arrow-btn--right"
            @click="slider.next()"
            aria-label="Slide suivante"
        ></button>
    </div>
</template>

<script>
import 'keen-slider/keen-slider.min.css';
import KeenSlider from 'keen-slider';
export default {
    name: "TextMediaSlider",
    props: {
        arrowsColor: {
            type: String,
            default: 'black',
        },
    },
    data() {
        return {
            current: 0,
            slider: null,
        }
    },
    computed: {
        nbSlides() {
            if (this.slider) {
                return this.slider.slides.length;
            }

            return null;
        },
    },
    mounted() {
        this.slider = new KeenSlider(this.$refs.slider, {
            initial: this.current,
            slides: {
                perView: 1,
            },
            breakpoints: {
                '(min-width: 1024px)': {
                    slides: {
                        perView: 1,
                    }
                }
            },
            slideChanged: (s) => {
                this.current = s.track.details.rel
            },
        })
    },
    beforeDestroy() {
        if (this.slider) this.slider.destroy()
    },
}
</script>

<style scoped lang="scss">

.keen-slider {
    @screen lg {
        @apply px-0;
    }

    &__slide {
        @apply flex flex-col items-center justify-start;

        @screen lg {
            @apply flex flex-row items-center justify-center;
        }
    }
}

.arrow-btn {
    &--left {
        top: 10%;
        left: 0;

        @screen sm {
            top: 17%;
            left: -2rem
        }

        @screen md {
            top: 20%;
        }

        @screen lg {
            top: 50%;
            left: -3rem;
        }

        @screen xl {
            left: -9rem;
        }
    }

    &--right {
        top: 10%;
        right: 0;

        @screen sm {
            top: 17%;
            right: -2rem
        }

        @screen md {
            top: 20%;
        }

        @screen lg {
            top: 50%;
            right: -3rem;
        }

        @screen xl {
            right: -9rem;
        }
    }
}
</style>
