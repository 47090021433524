<template>
    <form method="get" class="filters-form flex flex-col xl:flex-row">
        <h3 class="body xl:mr-1">{{ heading }}</h3>

        <div
            v-for="(filter, key) in filters" :key="key"
            class="flex flex-col my-2 xl:my-0 xl:ml-3"
        >
            <label class="mb-1" :for="`filter-${key}`">{{ filter.label }}</label>
            <select
                class="select select--black"
                :name="filter.name"
                :id="`filter-${key}`"
                :aria-label="filter.ariaLabel"
                @change="changeHandler"
            >
                <option value="">{{ filter.placeholder }}</option>
                <option
                    :value="option.value"
                    v-for="(option, key) in filter.options"
                    :key="`option-${key}`"
                    :selected="option.isSelected"
                >
                    {{ option.title }}
                </option>
            </select>
        </div>
    </form>
</template>

<script>
    export default {
        name: "FiltersForm",

        props: {
            heading: {
                type: String,
                required: true,
            },
            filters: {
                type: Array,
                required: true,
            },
        },

        methods: {
            changeHandler() {
                this.$el.submit();
            },
        },
    }
</script>

<style scoped lang="scss">
.select {
    @screen xl {
        max-width: 15rem;
    }
}
</style>
