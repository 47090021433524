import "./assets/styles/main.scss";

import Vue from "vue";
import CsrfInput from "@/app/components/CsrfInput";
import HeaderNav from "@/app/components/HeaderNav";
import SelectFilter from "@/app/components/SelectFilter";
import EventsOverview from "@/app/components/EventsOverview";
import FiltersForm from "@/app/components/FiltersForm";
import ScrollToTop from "@/app/components/ScrollToTop";
import Anchor from "@/app/components/Anchor";
import TextMediaSlider from "@/app/components/TextMediaSlider";

new Vue({
    el: "#app",
    components: {
        CsrfInput,
        HeaderNav,
        SelectFilter,
        EventsOverview,
        FiltersForm,
        ScrollToTop,
        Anchor,
        TextMediaSlider,
    },
});
