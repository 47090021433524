<template>
    <div class="events-overview overflow-hidden relative bg-black text-white pt-16 pb-8 lg:pt-40 lg:pb-16">
        <p ref="text" class="events-overview__text font-gt-america-regular absolute top-0 mt-6 lg:mt-16 whitespace-no-wrap">{{ text }}</p>

        <div class="relative">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "EventsOverview",

        props: {
            text: {
                type: String,
                required: true,
            },
        },

        mounted() {
            window.addEventListener('scroll', this.scrollHandler);

            this.scrollHandler();
        },

        destroyed() {
            window.removeEventListener('scroll', this.scrollHandler);
        },

        methods: {
            scrollHandler() {
                const rect = this.$el.getBoundingClientRect();

                if(!(rect.top >= 0 || rect.bottom >= 0)) {
                    return false;
                }

                const value = - (window.scrollY - this.$el.offsetTop) / (4);

                this.$refs.text.style.transform = `translateX(${value}px)`;
            },
        },
    }
</script>

<style scoped lang="scss">
    .events-overview {
        &__text {
            left: -20%;
            font-size: 3.5rem;
            letter-spacing: normal;

            transition: transform 200ms ease-out;

            @screen lg {
                left: 20%;
                font-size: 9.625rem;
            }
        }
    }
</style>
