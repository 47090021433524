<template>
    <div
        class="header-menu-mobile fixed inset-0 z-10 min-h-screen w-screen lg:hidden overflow-auto"
        :class="hasDarkBackground ? 'text-white': 'text-black'"
        :style="`background-color: ${backgroundColor}`"
    >
        <div class="grid-container container flex flex-col h-full">
            <div class="flex justify-between mb-8 pt-2">
                <a :href="currentSiteUrl" :title="homeLinkTitle">
                    <img class="header-menu-mobile__logo" :src="logo.url" :alt="logo.alt">
                </a>

                <button @click="$emit('close')">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <g fill="none" fill-rule="evenodd">
                            <g>
                                <path fill="currentColor" fill-rule="nonzero" d="M20.678 3l.707.707-8.486 8.485 8.486 8.486-.707.707-8.486-8.486-8.485 8.486L3 20.678l8.485-8.486L3 3.707 3.707 3l8.485 8.485L20.678 3z"/>
                            </g>
                        </g>
                    </svg>
                </button>
            </div>

            <div class="flex-1 flex flex-col justify-between">
                <nav class="flex flex-col pb-2"
                     :aria-label="ariaLabelNavigationHeader"
                >
                    <a
                        v-for="(item, key) in menuItems"
                        :href="item.url"
                        :target="item.target"
                        :key="`menu-item-${key}`"
                        class="h5 no-underline mb-6"
                    >
                        {{ item.label }}
                    </a>
                </nav>

                <nav class="flex flex-col border-t"
                     :class="hasDarkBackground ? 'border-white': 'border-black'"
                     :aria-label="ariaLabelNavigationBanner"
                >
                    <a
                        v-for="(item, key) in banner.menuItems"
                        :href="item.url"
                        :target="item.target"
                        :key="`banner-menu-item-${key}`"
                        class="header-menu-mobile__banner-item mr-6 font-gt-america-regular no-underline uppercase mt-6"
                    >
                        {{ item.label }}
                    </a>

                    <div class="flex justify-between items-center mt-8 pb-6">
                        <div class="flex">
                            <a
                                v-for="(item, key) in banner.socialNetworks"
                                :href="item.url"
                                target="_blank"
                                :title="item.label"
                                :key="`banner-social-networks-${key}`"
                                class="mr-4"
                            >
                                <img class="w-8 h-8" :src="getCurrentSocialLogo(item).url" :alt="getCurrentSocialLogo(item).alt">
                            </a>
                        </div>

                        <template v-for="(item, key) in banner.languagePicker">
                            <a
                                class="header-menu-mobile__banner-item font-gt-america-regular no-underline uppercase"
                                v-if="!item.isActive"
                                :href="item.url"
                                :key="`banner-language-${key}`"
                            >{{ item.locale }}</a>
                        </template>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "HeaderMenuMobile",

        props: {
            logo: {
                type: Object,
                required: true,
            },
            backgroundColor: {
                type: String,
                required: true,
            },
            hasDarkBackground: {
                type: Boolean,
                required: true,
            },
            menuItems: {
                type: Array,
                required: true,
            },
            banner: {
                type: Object,
                required: true,
            },
            ariaLabelNavigationHeader: {
                type: String,
                required: true,
            },
            ariaLabelNavigationBanner: {
                type: String,
                required: true,
            },
            currentSiteUrl: {
                type: String,
                required: true,
            },
            homeLinkTitle: {
                type: String,
                required: true,
            },
        },

        methods: {
            getCurrentSocialLogo(item) {
                return this.hasDarkBackground ? item.logo.light : item.logo.dark;
            }
        },
    }
</script>

<style scoped lang="scss">
    .header-menu-mobile {
        &__logo {
            width: 2.375rem;
            height: 2.5rem;
        }

        &__banner-item {
            font-size: 0.6875rem;
            letter-spacing: 0.114em;
        }
    }
</style>
